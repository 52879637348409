<template>
  <!--消息通知列表-->
  <div style="padding: 20px;">
    <div>

      <div style="display: flex;align-items: center;justify-content: space-between;">
        <span style="font-size: 24px;font-weight: 500;color: #707070;">消息通知</span>
        <el-button style="background: #E4E5E9!important;border-color: #E4E5E9!important;color: #3F4157!important;" @click="returnPage()">返回</el-button>
      </div>

      <!--搜索条件-->
      <el-card shadow="never" style="margin-top: 20px;">
        <div class="card">
          <div class="card_t">
            <div class="card_t_icon"></div>
            <div class="card_t_text">
              筛选条件
            </div>
          </div>
          <div class="card_b">
            <!-- 搜索表单 -->
            <el-form
                :model="where"
                label-width="77px"
                class="ele-form-search"
                @keyup.enter.native="reload"
                @submit.native.prevent>
              <el-row :gutter="15">
                <el-col :lg="6" :md="12">
                  <el-form-item label="标题:">
                    <el-input
                        clearable
                        v-model="where.facilitator_name"
                        placeholder="请输入标题"/>
                  </el-form-item>
                </el-col>
                <el-col :lg="6" :md="12">
                  <el-form-item label="发布时间:">
                    <el-date-picker
                        v-model="where.value1"
                        type="daterange"
                        unlink-panels
                        range-separator="至"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期">
                    </el-date-picker>
                  </el-form-item>
                </el-col>
                <el-col :lg="6" :md="12">
                  <div class="ele-form-actions">
                    <el-form-item label="">
                      <el-button
                          type="primary"
                          class="ele-btn-icon"
                          @click="QueryList">查询
                      </el-button>
                      <el-button @click="empty">清空</el-button>
                    </el-form-item>
                  </div>
                </el-col>
              </el-row>
            </el-form>
          </div>
        </div>
      </el-card>

      <!--数据表格-->
      <el-card shadow="never">
        <div class="card">
          <div class="Datalist">
            <div class="Datalist_l">
              <div class="Datalist_icon"></div>
              <div class="Datalist_text">
                数据列表
              </div>
              <!--<div class="custom" style="margin-left: 20px">-->
              <!--</div>-->
            </div>
            <div>
              <el-popconfirm title="确定执行此操作吗？" @confirm="all()">
                <el-button slot="reference">全部标记为已读</el-button>
              </el-popconfirm>
              <!--<el-button @click="ListSetup()">列表设置</el-button>-->
            </div>
          </div>

          <div class="card_b">
            <!-- 数据表格 -->
            <my-table
                ref="myTable"
                :columns="columns"
                :tableData="List"
                :cellStyle="cellStyle"
                :headerCellStyle="headerCellStyle"
                :rowClassName="rowClassName"
                :total="total"
                :customsFromWhere="where"
                :loading="loading"
                @select="select"
                @ChangeSize="ChangeSize"
                @currentChange="currentChange"
            >

              <!--自定义列显示 示例-->
              <template v-slot:priority= "{ scope }">
                <div v-if="scope.row.priority ==  '中'">
                  <span style="background: #FFF0D9;padding: 5px 0 5px 0;display: block;">
                    <span style="font-size: 14px;font-weight: 400;color: #ff9b05;">中</span>
                  </span>
                </div>
                <div v-if="scope.row.priority ==  '高'">
                  <span style="background: #FFDEDE;padding: 5px 0 5px 0;display: block;">
                    <span style="font-size: 14px;font-weight: 400;color: #FF2525;">高</span>
                  </span>
                </div>
                <div v-if="scope.row.priority == '低'">
                  <span style="background: #E5F8F1;padding: 5px 0 5px 0;display: block;">
                    <span style="font-size: 14px;font-weight: 400;color: #53D1A1;">低</span>
                  </span>
                </div>
              </template>


              <template v-slot:is_read= "{ scope }">
                <span v-if="scope.row.is_read == 1">已读</span>
                <span v-if="scope.row.is_read == 0">未读</span>
              </template>

              <!-- 不使用数据可不接收scope值 -->
              <template v-slot:operationSlot= "{ scope }">
                <el-link :underline="false" type="warning" @click="examine(scope.row)">查看</el-link>
              </template>

            </my-table>

          </div>
        </div>
      </el-card>

      <!--列表设置弹窗-->
      <div class="dialog">
        <el-dialog
            title="列表设置"
            :visible.sync="ListSetupShow"
            width="60%"
            center
            :before-close="handleClose">
        <span>
           <PackageSortElement :AllParameters="columns"   @change="SortList" @show="dialogshow"></PackageSortElement>
        </span>
        </el-dialog>
      </div>

      <el-dialog
          center
          :title="title"
          :visible.sync="dialogVisible"
          width="30%"
          :before-close="handleClose">
        <span>
          {{content}}
        </span>
        <div slot="footer">
          <el-button
              @click="dialogVisible = false">取消
          </el-button>
          <el-button
              style="margin-left: 20px;"
              type="primary"
              :loading="loading"
              @click="dialogVisible = false">确定
          </el-button>
        </div>
      </el-dialog>

    </div>

  </div>
</template>

<script>
// 引入表格设置封装的组件
import PackageSortElement from "@/components/PackageSortElement";
// 权限
import {ShowTable} from '@/utils'
// 引入的接口
import {getnote, Detalisnote, Setnote_all} from "@/api/operate";


export default {
  // 组件生效
  components: {
    PackageSortElement,
  },
  data(){
    return{
      // 表格搜索条件
      where: {
        page: 1, // 当前页数
        limit: 10 // 每页显示条目个数
      },
      // 表格数据
      List: [],
      // 总条目数
      total: 0,
      //加载动画
      loading:true,
      // 表头数据
      columns: [
        {
          type: "selection",
          isShow: true
        },
        {
          label: "优先级",
          prop: "priority",
          slot: "priority",
          width:'80',
          isShow: true
        },
        {
          label: "消息类型",
          prop: "notice_type",
          isShow: true
        },
        {
          label: "标题",
          prop: "title",
          isShow: true
        },
        {
          label: "发布人",
          prop: "realname",
          isShow: true
        },
        {
          label: "发布时间",
          prop: "time",
          isShow: true
        },
        {
          label: "阅读状态",
          prop: "is_read",
          slot:'is_read',
          isShow: true
        },
        {
          label: "操作",
          width: "100",
          slot: "operationSlot",
          isShow: true
        },
      ],

      // 列表设置弹窗
      ListSetupShow:false,

      //编辑司机模块
      TechnicianEditcurrent:null,
      showTechnicianEdit:false,

      //司机详情模块
      ShowTechnicianDetails:false,

      //查看弹窗
      dialogVisible:false,
      title:'',
      content:'',

    }
  },

  // 生命周期 - 挂载完成（可以访问 DOM 元素）
  mounted() {
    //调用获取客户信息列表方法
    this.getList();
  },

  methods:{
    // 获取列表
    getList(){
      getnote(this.where).then(async (res) => {
        this.total = res.data.count;
        this.List = res.data.list;
        this.loading = false;
      }).catch((res) => {
        this.$Message.error(res.msg);
        this.loading = false;
      })
    },

    // 查询条件
    QueryList(){
      this.getList();
    },
    // 清空
    empty(){
      this.where = {
        page: 1, // 当前页数
        limit: 10 // 每页显示条目个数
      }
      this.getList();
    },

    // 当前页数改变事件
    currentChange(val) {
      console.log("当前页数", val)
      this.where.page = val;
      this.getList();
    },
    // 当用户手动勾选数据行的 Checkbox 时触发的事件
    select(selection, row) {
      console.log(selection)
      console.log("选择的所有数据行", selection)
      console.log("当前选择的数据行", row)
    },
    ChangeSize(val){
      console.log('当前页面显示的条数',val);
      this.where.limit = val;
      this.getList()
    },

    // 表头行的 className 的回调方法
    headerRowClassName({ row, rowIndex }) {
      return "header_row_class_name"
    },
    // 表头单元格的 style 的回调方法
    headerCellStyle({ row, column, rowIndex, columnIndex }) {
      return "background: #F7F8FA"
    },
    // 行的 className 的回调方法
    rowClassName({ row, rowIndex }) {
      return "row_class_name"
    },
    // 单元格的 style 的回调方法
    cellStyle({ row, column, rowIndex, columnIndex }) {
      if (rowIndex == 1 && columnIndex == 3) {
        // return {
        //   background: "skyBlue",
        //   "border-radius": "20px"
        // }
      }
    },
    // 仅对 type=selection 的列有效，Function(row, index) 用来决定这一行的 CheckBox 是否可以勾选
    selectable(row, index) {
      if (row.sex == "女") {
        return false
      } else {
        return true
      }
    },

    // 删除
    del(row){
      console.log(row);
      this.$utils.confirm("确定要删除吗？", function () {
        // 执行一些需要的逻辑
        console.log('点击确定')
      }, "确定", 'warning');
    },

    // 点击列表设置
    ListSetup(){
      this.ListSetupShow = true;
    },
    // 列表设置关闭
    handleClose(){
      this.ListSetupShow = false;
    },
    //事件  拿到从组件内部处理的数据顺序并赋值
    SortList(value){
      // 接收子组件传递的值替换当前列表显示顺序
      this.columns = value;
    },
    dialogshow(value){
      this.ListSetupShow = value;
    },

    //点击查看按钮
    examine(row){
      console.log(row);
      this.title = row.title;
      this.content = row.content;
      Detalisnote(row.id).then(res => {
        console.log(res)
        if(res.code === 200){
          this.getList();
          this.dialogVisible = true;
        }else {
          this.$message.error(res.msg)
        }
      }).catch(e => {
        this.$message.error(e.msg);
      })
    },

    //全部已读
    all(){
      Setnote_all().then(res => {
        if(res.code === 200){
          this.$message.success(res.msg)
          this.getList();
        }else {
          this.$message.error(res.msg)
        }
      }).catch(e => {
        this.$message.error(e.msg);
      })
    },

    //点击返回按钮
    returnPage(){
      this.$router.go(-1);
    },

  }

}
</script>

<style lang="scss" scoped>

</style>
